<template>
  <div>
    <el-form v-model='formData' size='mini'>
      <div style='width: 100%;display: flex;flex-direction: row;gap: 5px;justify-content: space-between'>
        <div class='box-shadow' style='padding: 10px'>
          <el-form-item label=''>
            <div>
              <span style='font-weight: 800'>当月价格</span>
            </div>
            <div style='display: flex;flex-direction: row;gap: 5px;justify-content: flex-start'>
              <div>
                <PriceGrids v-if='formData.current_quote' :rows='formData.current_quote' :platform-type='platformType' />
              </div>
              <el-button type='primary' :plain="!(formData.quote_source==='current_quote')" icon='el-icon-circle-check'
                         class='check-btn'
                         @click="selectQuote(formData.current_quote,'current_quote')"></el-button>
            </div>
          </el-form-item>
        </div>
        <div class='box-shadow' style='padding: 10px'>
          <el-form-item label=''>
            <div>
              <span style='font-weight: 800'>下月估价</span>
            </div>
            <div style='display: flex;flex-direction: row;gap: 5px;justify-content: flex-start'>
              <div>
                <PriceGrids v-if='formData.next_quote' :rows='formData.next_quote' :platform-type='platformType' />
              </div>
              <el-button type='primary' :plain="!(formData.quote_source==='next_quote')" icon='el-icon-circle-check'
                         class='check-btn'
                         @click="selectQuote(formData.next_quote,'next_quote')"></el-button>
            </div>
          </el-form-item>
        </div>
      </div>
      <br />
      <div>
        <AdjustPricePanel
          :quote-info.sync='formData.real_quote'
          :show-single-price='priceTypeMap.single'
          :show-multi-price='priceTypeMap.multi'
          :platform-type='platformType'
          :title='`商业化营销中心终价`'
          :edit-price='true' />
      </div>
      <el-row :gutter='10'>
        <el-col :span='24'>
          <el-form-item label='' class='edit-box'>

          </el-form-item>
          <el-form-item style='text-align: center' label-width='0px'>
            <el-button type='primary' size='small' @click='saveQuote' :disabled='loadingSb' :loading='loadingSb'
                       v-if="userPermissions.indexOf('plc_save_quote')>-1">
              保存
            </el-button>
            <el-button @click='notifyByDing' size='small' v-if="userPermissions.indexOf('plc_save_quote')>-1"
                       :disabled='loadingNotify' :loading='loadingNotify'>
              通知定价
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PriceGrids from '@/pages/publication/PriceGrids'
import AdjustPricePanel from '@/pages/publication/adjust/AdjustPricePanel'
import plcMap from '@/json/plc.json'

const { deepClone } = require('@/utils')

export default {
  name: 'RealQuoteEdit',
  components: { AdjustPricePanel, PriceGrids },
  computed: {
    ...mapGetters(['userPermissions']),
    //报价方式：品类报价（1：n）；类目报价
    priceTypeMap() {
      let customPriceMap = plcMap.customPriceMap

      return customPriceMap[this.platformType] || {
        'multi': true,
        'single': true
      }
    }
  },
  props: {
    id: {
      type: [Number, String],
      default() {
        return null
      }
    },
    platformType: {
      type: String,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      formData: {
        id: null,
        quote_source: null,
        current_quote: { multi: [], single: [] },
        next_quote: { multi: [], single: [] },
        real_quote: { multi: [], single: [] }
      },
      loadingSb: false,
      loadingNotify: false
    }
  },
  // watch: {
  //   id() {
  //     this.getInfo()
  //   }
  // },
  methods: {
    init() {
      // this.$notify.info(`数据加载中...${this.id}`)
      this.getInfo()
    },
    async getInfo() {
      if (this.id) {
        let { info } = await this.$api.getPublicationItemInfo(this.id)
        this.$nextTick(() => {
          let { id, quote_source, current_quote, next_quote, real_quote } = info
          this.formData = {
            id: id,
            quote_source: quote_source,
            current_quote: current_quote,
            next_quote: next_quote,
            real_quote: real_quote || { multi: [], single: [] }
          }
          this.$forceUpdate()
        })
      }
    },
    async saveQuote() {
      //保存最终价格
      this.loadingSb = true
      let params = { id: this.formData.id, real_quote: this.formData.real_quote }
      if (this.formData.quote_source) {
        params['quote_source'] = this.formData.quote_source
      }
      let id = await this.$api.saveRealQuote(params)
      if (id) {
        this.$emit('saved', this.formData.real_quote)
      }
      this.loadingSb = false

    },
    selectQuote(quotes, quote_source) {
      this.formData.quote_source = quote_source
      this.formData.real_quote = deepClone(quotes)
    },
    async notifyByDing() {
      this.loadingNotify = true
      // this.$notify.warning('定价通知：定价与你的预估价格不同，通知知晓！！！（开放中...）')
      await this.$api.handleRealQuoteNotify(this.formData.id)
      this.$notify.success('通知已发送')
      this.loadingNotify = false
    }
  },
  created() {
    this.getInfo()
  }
}
</script>

<style scoped>
.quote-input {
  margin-bottom: 5px;
}

.edit-box {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.check-btn {
  margin-left: 10px;
}

.item {
  display: flex;
}

.is-label {
  flex: 3;
}

.item-val {
  flex: 3;
  margin-left: 5px;
}

.item-remark {
  flex: 5;
  margin-left: 5px;
}
</style>
