<template xmlns:el-col='http://www.w3.org/1999/html'>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-link class='fanhui-button'
                 type='primary'
                 icon='el-icon-d-arrow-left'
                 @click='backParentPage'>返回
        </el-link>
        <div class='plc'>
          <div class='title-row'>
            <div style='display: inline-block;margin-right: 5px'>
              <el-image style='width: 32px;height: 32px' fit='contain'
                        :src="`${info.platform_info?info.platform_info.logo_url:''}`"
                        :alt='`平台LOGO`' v-if='info.platform_info' />
            </div>
            <div class='title'>{{ info.title }}</div>
            <div class='tag'>（<span class='date'>{{
                info.validity_date || '未设置开始日期'
              }} ~ {{ info.expiry_date || '未设置截止日期' }}</span>）<i class='el-icon-edit' @click='editBtn'
                                                                 v-if="userPermissions.indexOf('plc_edit')>-1"></i>
            </div>
          </div>
          <div class='notice'>
            <div>
              <span class='notice-title'>合作须知：</span>
              <el-link type='primary' @click='editBtn' v-if="userPermissions.indexOf('plc_edit')>-1"
                       icon='el-icon-edit'></el-link>
            </div>
            <div class='info_box' v-html='info.notice'></div>
            <!--            <el-input v-model="info.notice" type="textarea" :rows="10" placeholder="合作须知" show-word-limit-->
            <!--                      maxlength="500" readonly/>-->
          </div>

          <!--          筛选条件-->
          <div :class="['row-opt',fixHeader?'fixed-header':'',isCollapse?'fold':'unfold']">
            <div class='table-search'>
              <el-form size='mini' :inline='true' :model='searchCondition'>
                <el-form-item>
                  <el-button type='primary' icon='el-icon-plus'
                             v-if="userPermissions.indexOf('plc_adjust')>-1"
                             @click='addAPlcDetail'>
                    刊例提报
                  </el-button>
                </el-form-item>
                <el-form-item>
                  <el-radio-group v-model='tabActive'>
                    <el-radio-button label='status' value='status'
                                     v-if="userPermissions.indexOf('plc_save_quote')>-1">
                      待定价
                    </el-radio-button>
                    <el-radio-button label='apply_status' value='apply_status'
                                     v-if="userPermissions.indexOf('plc_save_quote')>-1">
                      改价申请
                    </el-radio-button>
                    <el-radio-button label='my' value='my'>我的</el-radio-button>
                    <el-radio-button label='all' value='all'>全部</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label='' v-if="userPermissions.indexOf('plc_save_quote')>-1">
                  <artist-search :name.sync='searchCondition.nickname' @handleSelect='chooseArtist'
                                 @clicked='searchForm' />
                </el-form-item>
                <el-form-item>
                  <el-button type='primary' icon='el-icon-search' @click='searchForm'>搜索</el-button>
                </el-form-item>
                <el-form-item label='' style='float: right'>
                  <el-button type='danger' icon='el-icon-delete' @click='delRow(info.id)'
                             v-if="userPermissions.indexOf('plc_delete')>-1" title='删除当前刊例'>删除
                  </el-button>
                </el-form-item>
                <!--                <el-form-item>-->
                <!--                  <el-button type='warning' icon='el-icon-brush' @click='fixRows()'-->
                <!--                             style='display: none'>一键修复商务案例({{ processBarNumber }}/{{ dataList.length }})-->
                <!--                  </el-button>-->
                <!--                </el-form-item>-->
              </el-form>
            </div>
          </div>

          <div class='plc-items' v-loading='loadingStatus' element-loading-text="刊例提报数据加载中……"
               element-loading-spinner="el-icon-loading">
            <div>
              <m-pagination :total.sync='pagingData.total' :page.sync='pagingData.current_page'
                            :limit.sync='pagingData.page_size' @pagination='getPageList'></m-pagination>
            </div>
            <el-table
                      lazy
                      ref='detailTable'
                      :data='dataList'
                      @sort-change='changeTableSort'
                      :header-row-style='headerStyle'
                      border>
              <el-table-column label='基本信息' width='270' align='center' header-align='center' fixed='left'>
                <template slot-scope='scope'>
                  <PanelKolInfo :data='scope.row' :index='scope.$index' style='height: 100%'
                                @editIntro='editVal' :platform-type='setting.type' />
                </template>
              </el-table-column>
              <el-table-column label='其他信息' width='300' align='left' header-align='center' fixed='left'>
                <template slot-scope='scope'>
                  <PanelPlatformInfo :data='scope.row' />
                </template>
              </el-table-column>
              <el-table-column v-if='isShowAll' prop='current_quote' label='当月价格' min-width='280'
                               align='center'>
                <template slot-scope='{row,column,$index}'>
                  <div class='price-div'>
                    <div class='edit-btn'>
                      <div class='edit-quote' v-if="userPermissions.indexOf('plc_save_quote')>-1">
                        <el-link :underline='false' type='primary'
                                 v-if="row['quote_source']===column.property">
                          <i class='el-icon-success edit-btn-size'></i>
                        </el-link>
                        <el-link :underline='false' type='primary'
                                 @click='selectQuote(row,$index,column.property)'
                                 v-else>
                          <i class='el-icon-circle-check edit-btn-size'></i>
                        </el-link>
                      </div>
                    </div>
                    <div class='edit-box'>
                      <PriceGrids :rows='row[column.property]' :platform-type='setting.type' v-if='setting.type' />
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-if='isShowAll' prop='next_quote' label='下月预估价格' min-width='280'
                               align='center'>
                <template slot-scope='{row,column,$index}'>
                  <div class='price-div'>
                    <div class='edit-btn'>
                      <div class='edit-quote' v-if="userPermissions.indexOf('plc_save_quote')>-1">
                        <el-link :underline='false' type='primary'
                                 v-if="row['quote_source']===column.property">
                          <i class='el-icon-success edit-btn-size'></i>
                        </el-link>
                        <el-link :underline='false' type='primary'
                                 @click='selectQuote(row,$index,column.property)'
                                 v-else>
                          <i class='el-icon-circle-check edit-btn-size'></i>
                        </el-link>
                      </div>
                    </div>
                    <div class='edit-box'>
                      <PriceGrids :rows='row[column.property]'
                                  :apply-record="row['apply_record']" :field='column.property'
                                  :platform-type='setting.type' v-if='setting.type' />
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop='real_quote' label='商业化营销中心终价' min-width='280' header-align='center'
                               align='left'>
                <template slot-scope='{row,column,$index}'>
                  <div class='price-div'>
                    <div class='edit-box'>
                      <PriceGrids :rows='row[column.property]' :platform-type='setting.type' v-if='setting.type' />
                    </div>
                    <div class='edit-btn'>
                      <div class='edit-quote' v-if="userPermissions.indexOf('plc_save_quote')>-1">
                        <el-link :underline='false' type='primary' @click='editRow(row,$index)'>
                          <i class='el-icon-edit-outline edit-btn-size'></i>
                        </el-link>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label='商务案例' prop='bs_case' min-width='200' header-align='center' align='left'>
                <template slot-scope='{row,column,$index}'>
                  <div class='price-div'>
                    <div class='edit-box'>
                      <div class='bs_case' v-html='row.bs_case' v-if='row.bs_case'></div>
                      <div v-else style='text-align: center;vertical-align: middle'>/</div>
                    </div>
                    <div class='edit-btn'>
                      <div class='edit-quote' v-if="userPermissions.indexOf('plc_save_quote')>-1">
                        <el-link :underline='false' type='primary'
                                 @click='editVal(row,column.property,$index)'>
                          <i class='el-icon-edit-outline edit-btn-size'></i>
                        </el-link>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label='状态 / 操作' width='160' align='center'>
                <template slot-scope='scope'>
                  <div style='display: flex;flex-direction: column'>
                    <div class='status'>
                      <div style='display: flex;flex-direction: column'>
                        <div>
                          <el-tag size='small' :type='calcStatus(scope.row.status)'
                                  effect='plain'>
                            {{ scope.row.status_alias }}
                          </el-tag>
                        </div>
                        <div style='margin-top: 5px'>
                          <el-tag size='small' :type='calcApplyStatus(scope.row.apply_status)'
                                  effect='plain'>{{
                              scope.row.apply_status_alias
                            }}
                          </el-tag>
                        </div>
                        <div style='margin-top: 5px'>
                          <el-tag size='small'
                                  :type='calcOverTimeStatus(scope.row.is_overtime)'
                                  effect='plain'>{{
                              scope.row.is_overtime_alias
                            }}
                          </el-tag>
                        </div>
                      </div>
                    </div>
                    <div class='opt'>
                      <div style='display: flex;flex-direction: column'>
                        <div>
                          <el-button type='text' @click='jumpToPlcItemDetail(scope.row)'
                                     icon='el-icon-warning-outline'
                                     v-if="userPermissions.indexOf('plc_save_quote')>-1&&scope.row.apply_status===2"
                                     style='color: #FFBA00'>
                            审核
                          </el-button>
                        </div>
                        <div>
                          <el-button type='text'
                                     @click='editAPlcDetail(scope.row,scope.$index)'
                                     icon='el-icon-edit'
                                     v-if="userPermissions.indexOf('plc_adjust')>-1">
                            修改
                          </el-button>
                        </div>
                        <div v-if="isShowAll||userPermissions.indexOf('plc_save_quote')>-1">
                          <el-button type='text' @click='delPlcItem(scope.row,scope.$index)'
                                     icon='el-icon-delete'
                                     v-if="userPermissions.indexOf('plc_item_delete')>-1">
                            删除
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div>
              <m-pagination :total.sync='pagingData.total' :page.sync='pagingData.current_page'
                            :limit.sync='pagingData.page_size' @pagination='getPageList'></m-pagination>
            </div>

          </div>
        </div>
      </div>
    </PageHeaderLayout>
    <!--      修改基础信息-->
    <ModalDialog :dialogData='dialogData' @dialogClose='dialogClose'>
      <template slot='content'>
        <PublicationEdit :id='id' @saved='dialogClose' />
      </template>
    </ModalDialog>

    <!--      修改提报信息信息-->
    <ModalDialog :dialogData='dialogPlcData' @dialogConfirm='handlePlcConfirm' @dialogClose='dialogPlcClose'>
      <template slot='content'>
        <el-form label-width='100px'>
          <el-form-item label='KOL简介' v-if="editField==='intro'">
            <el-input v-model='formItemData.intro' type='textarea' :rows='8' placeholder='请输入KOL简介'
                      show-word-limit
                      maxlength='500' />
          </el-form-item>
          <el-form-item label='商务案例' v-if="editField==='bs_case'">
            <ApeEditor
              :inline='true'
              :init-html='formItemData.bs_case'
              :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify ' +
          '  | removeformat anchor hr |' +
          ' code preview fullscreen'"
              @handleTinymceInput='handleTinymceInput'></ApeEditor>
          </el-form-item>

        </el-form>
      </template>
    </ModalDialog>

    <!--      修改终价信息-->
    <ModalDialog :dialogData='dialogQuoteData' @dialogClose='dialogQuoteClose'>
      <template slot='content'>
        <RealQuoteEdit :platform-type='setting.type' ref='refRealQuoteEdit' :id='formData.id' @saved='savedQuote' />
      </template>
    </ModalDialog>
    <!--    数据录入（添加）-抽屉-->
    <el-drawer
      :title='drawerData.title'
      :visible.sync='drawerData.visible'
      direction='rtl'
      :size='drawerData.width_height'
    >
      <div slot='title' style='font-size: 1.2em;font-weight: bold'>{{ drawerData.title }}</div>
      <AdjustCreateEdit
        ref='refCreateEdit'
        :key='`AdjustCreateEdit_${editFormData.id||"0"}`'
        :pid='id'
        :setting='setting'
        :id='editFormData.id' :year='info.year' :month='info.month' :platform-info='info.platform_info'
        @saved='refreshList' @deleted='refreshList' @updated='refreshCurrentRow'
        style='height: 80vh' />

    </el-drawer>

  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ModalDialog from '@/components/ModalDialog'
import PublicationEdit from '@/pages/publication/BaseEdit'
import ApeTable from '@/components/ApeTable'
import RealQuoteEdit from '@/pages/publication/RealQuoteEdit'
import { mapGetters } from 'vuex'
import ExportLink from '@/components/export/ExportLink'
import PriceGrids from '@/pages/publication/PriceGrids'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import EditableCell from '@/components/EditableCell'
import ArtistSearch from '@/components/artist/ArtistSearch'
import AdjustCreateEdit from '@/pages/publication/adjust/CreateEdit'
import ApeDrawer from '@/components/ApeDrawer'
import ExportBtn from '@/components/export/ExportBtn'
import PanelPlatformInfo from '@/pages/publication/compoents/PanelPlatformInfo'
import PanelKolInfo from '@/pages/publication/compoents/PanelKolInfo'
import ApeEditor from '@/components/ApeEditor'
import { html2json } from 'html2json'
import MlPageTable from '@/components/common/MlPageTable'

const { deepClone } = require('@/utils')
export default {
  name: 'PlcDetail',
  computed: {
    ...mapGetters(['isCollapse', 'userPermissions', 'userInfo']),
    isShowAll() {
      //我的，定价，改价申请
      return ['status', 'apply_status', 'my'].indexOf(this.tabActive) > -1
    }
  },
  components: {
    MlPageTable,
    PanelKolInfo,
    PanelPlatformInfo,
    ExportBtn,
    ApeDrawer,
    ArtistSearch,
    PriceGrids,
    RealQuoteEdit,
    ApeTable,
    PublicationEdit,
    ModalDialog,
    ExportLink,
    PageHeaderLayout,
    ArtistSearchCustom,
    EditableCell,
    AdjustCreateEdit,
    ApeEditor
  },
  data() {
    return {
      top: 100,
      fixHeader: false,
      searchCondition: {},
      tabActive: 'all',
      id: null,
      info: { title: '', validity_date: '', expiry_date: '', platform_info: {} },
      needTip: true,
      dataList: [],
      processBarNumber: 0,
      loadingStatus: false,
      orderSort: { tag: 'desc', fans_total: 'desc', id: 'desc' },//默认排序规则
      offset: 0,
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 10,
        current_page: 1
        // offset: 0,
      },
      dialogData: {
        visible: false,
        title: '刊例基础资料编辑',
        width: '80%',
        loading: true,
        modal: false,
        hide_footer: true
      },
      dialogPlcData: {
        visible: false,
        title: '刊例提报信息编辑',
        width: '1300px',
        loading: true,
        modal: false,
        hide_footer: false
      },
      formData: {},
      formItemData: { id: null, base_case: null, intro: null, bs_case_rich: null },
      editField: null,//当前编辑的字段
      editRowIndex: null,//当前修改的行号
      dialogQuoteData: {
        visible: false,
        title: '商业化营销中心终价',
        width: '980px',
        loading: true,
        modal: false,
        hide_footer: true
      },
      // quoteMap: {},
      tagMap: {
        'new': 'NEW',
        'benefit': '惠',
        'recommend': '荐'
      },
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '刊例录入',
        width_height: '1200px',
        show_footer: false,
        // mask: false,
        close_name: '关 闭'
        // confirm_name: '打 印',
      },
      editFormData: {},
      //当前刊例对应的设置
      setting: {}
    }
  },
  watch: {
    tabActive(val) {
      switch (val) {
        case 'status':
          this.searchCondition.status = 1
          this.searchCondition.apply_status = null

          break
        case 'apply_status':
          this.searchCondition.user_id = null
          this.searchCondition.status = null
          this.searchCondition.apply_status = 2
          break
        case 'all':
          this.searchCondition.user_id = null
          this.searchCondition.status = null
          this.searchCondition.apply_status = null
          break
        case 'my':
          this.searchCondition.user_id = this.userInfo.id
          this.searchCondition.status = null
          this.searchCondition.apply_status = null

          break
        default:

          break
      }
      this.getPageList()
    }
  },
  methods: {
    async loadSetting(platform_code) {
      let { info } = await this.$api.getPlatformSettingInfoByPlatformCode(platform_code)
      this.setting = info || {}
    },
    //编辑器
    handleTinymceInput(val) {
      this.formItemData.bs_case = val
    },
    addAPlcDetail() {
      this.editFormData = {}
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `刊例提报-录入`
      this.drawerData.visible = true
      this.drawerData.loading = false
      this.$nextTick(() => {
        this.$refs['refCreateEdit'].add()
      })
    },
    editAPlcDetail(row, index) {
      this.editFormData = { ...row }
      this.editRowIndex = index
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `刊例提报-编辑（ID:${row.id}）`
      this.drawerData.visible = true
      this.drawerData.loading = false
      this.$nextTick(() => {
        this.$refs['refCreateEdit'].edit(row)
      })
    },
    refreshCurrentRow() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      //刷新当前编辑
      this.getItemInfo(this.editRowIndex)//更新数据
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      // this.getPageList()
    },
    refreshList() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      //添加新记录后，刷新列表
      this.getPageList()
    },
    drawerOpen() {
      this.$nextTick(() => {
        this.$refs['refCreateEdit'].init()
      })
    },
    // row, rowIndex
    headerStyle() {
      return 'headerColor'
    },
    /**
     * 获取配置
     * @returns {Promise<void>}
     */
    // async getSetting() {
    //   let {quote_setting} = await this.$api.getPublicationSetting({quote_type: 'platform_quote'});
    //   this.quoteMap = quote_setting
    // },
    async getInfo() {
      let { info } = await this.$api.getPublicationInfo(this.id)
      if (info && info.platform_code)
        await this.loadSetting(info.platform_code)
      this.$nextTick(() => {
        this.info = info
        if (!this.info.notice && this.needTip) {
          this.editBtn()
          this.needTip = false// 标记不需要载提示
        }

      })
    },
    editBtn() {
      this.dialogData.visible = true
      this.dialogData.loading = false
    },
    // 处理模态框，确定事件
    handleConfirm() {
      this.dialogData.loading = false
      this.dialogData.visible = false
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.dialogData.loading = false
      this.dialogData.visible = false
      this.getInfo()
    },
    // 搜索
    searchForm() {
      // this.offset = 0
      this.pagingData.current_page = 1

      this.getPageList()

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = { pid: this.id }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.user_id) {
        condition.user_id = this.searchCondition.user_id
      }
      if (this.searchCondition.status) {
        condition.status = this.searchCondition.status
      }
      if (this.searchCondition.apply_status) {
        condition.apply_status = this.searchCondition.apply_status
      }
      // console.log('c', condition)
      return condition
    },
    async getPageList() {
      this.loadingStatus = true
      let pagingInfo = { page_size: this.pagingData.page_size, current_page: this.pagingData.current_page }
      let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, pagingInfo)
      Object.assign(searchCondition, pagingInfo, { order: this.orderSort })
      this.dataList = []
      let { list, pages } = await this.$api.getPublicationItemList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
      })
      this.pagingData.total = pages.total
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      this.offset = pages.offset
      this.loadingStatus = false
    },
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getPageList()
    },
    selectQuote(row, index, field) {
      if (this.dataList[index]['real_quote'].length !== 0) {
        this.$confirm(`已定价，确定替换?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //替换刊例的终价
          this.replaceQuote(row, index, field)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else {
        this.replaceQuote(row, index, field)
      }
    },
    async replaceQuote(row, index, field) {
      console.log(row,index,field)
      let real_quote = deepClone(row[field])
      if (real_quote) {
        //保存最终价格  //发请请求保存
        let params = { id: row.id, real_quote: real_quote, quote_source: field }
        let id = await this.$api.saveRealQuote(params)
        if (id) {
          //刷新数据
          this.dataList[index]['real_quote'] = deepClone(real_quote)
          this.dataList[index]['quote_source'] = field
          await this.getItemInfo(index)//更新数据
        }
      }
    },

    editRow(row, index) {
      this.formData = row
      this.dialogQuoteData.loading = false
      this.dialogQuoteData.visible = true
      this.dialogQuoteData.title = `商业化营销中心终价【KOL：${row.nickname || '--'} (${row.year}年${row.month}月)】`
      this.$nextTick(() => {
        this.$refs['refRealQuoteEdit'].init(row)
      })
      this.editRowIndex = index
    },
    // 处理模态框，关闭事件
    dialogQuoteClose() {
      this.dialogQuoteData.loading = false
      this.dialogQuoteData.visible = false
    },
    async savedQuote(real_quote) {
      this.dialogQuoteData.loading = false
      this.dialogQuoteData.visible = false

      if (real_quote) {
        if (this.dataList[this.editRowIndex]) {
          this.dataList[this.editRowIndex]['real_quote'] = deepClone(real_quote)
          this.dataList[this.editRowIndex]['status'] = 2
          await this.getItemInfo(this.editRowIndex)//更新数据
        }
      }
    },

    async delRow(id) {
      this.$confirm(`确定删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        //删除刊例（要求刊例明细已全部删除）
        let info = await this.$api.deletePublicationInfo(id)
        if (info === 'ok') {
          await this.$alert('删除成功', '提示', {
            confirmButtonText: '确定',
            callback: action => {
              if (action === 'confirm') {
                this.backParentPage()
                // window.opener.location.reload(true);
                // window.close()
              }
            }
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async fixRows() {
      //修复案例
      this.dataList.forEach((item) => {
        this.processBarNumber++
        item.bs_case_rich = html2json(item.bs_case)
        let data = {
          id: item.id,
          bs_case_rich: item.bs_case_rich
        }
        let id = this.$api.savePlcItem(data)
        if (id) {
          console.log(`ID:${data.id}(${this.processBarNumber}/${this.dataList.length})`)
        }
      })

    },
    backParentPage() {
      this.$router.push({ name: 'plc_list', params: { year: this.info.year, month: this.info.month } })
    },

    async selectArtist(selectInfo) {
      // console.log('si', selectInfo)
      this.searchCondition.artist_id = selectInfo.artist_id
      // this.searchCondition.dept_id = selectInfo.group_id
      this.searchForm()
    },

    //修改刊例提报信息
    editVal(row, field, index) {
      this.formItemData = { id: row.id }
      this.$set(this.formItemData, field, row[field] || '')

      this.editRowIndex = index
      this.editField = field

      this.dialogPlcData.visible = true
      this.dialogPlcData.loading = false
    },
    dialogPlcClose() {
      this.dialogPlcData.loading = false
      this.dialogPlcData.visible = false
    },
    async handlePlcConfirm() {

      let data = {
        id: this.formItemData.id
      }
      // data[this.editField] = this.formItemData[this.editField]

      data[this.editField] = this.formItemData[this.editField]
      data.bs_case_rich = html2json(this.formItemData[this.editField])
      let id = await this.$api.savePlcItem(data)
      if (id) {
        this.msgSuccess('修改成功')
        this.dataList[this.editRowIndex][this.editField] = this.formItemData[this.editField]
        this.dialogPlcData.loading = false
        this.dialogPlcData.visible = false
        await this.getItemInfo(this.editRowIndex)//更新数据
      }
    },

    delPlcItem(row, index) {
      this.$confirm(`确定删除（KOL：${row.nickname}）刊例吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deletePublicationItem(row.id)
        if (info) {
          this.$notify.success('删除成功')
          this.dataList.splice(index, 1)

        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    calcStatus(status) {
      //`${scope.row.status===2?'success':'warning'
      switch (status) {
        case 1:
          return 'warning'
        case 2:
          return 'success'
      }
    },
    calcOverTimeStatus(status) {
      //`${scope.row.status===2?'success':'warning'
      switch (status) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
      }
    },
    calcApplyStatus(status) {
      //`${scope.row.apply_status===3?'success':'danger'}`
      switch (status) {
        case 1:
          return 'warning'
        case 2:
          return 'success'
        case 3:
          return 'danger'
        default:
          return 'info'
      }
    },
    //刷新一行数据
    async getItemInfo(index) {
      let id = this.dataList[index].id
      let { info } = await this.$api.getPublicationItemInfo(id)
      this.dataList[index] = deepClone(info)
      this.$forceUpdate()
    },
    jumpToPlcItemDetail(row) {
      this.$router.push({
        name: 'plc_item', params: { id: row.id }
      })
    },
    //检索红人
    async chooseArtist(item) {
      if (item) {
        // this.searchCondition.group_id = item.group_id
        // this.searchCondition.group_name = item.group_name
        this.searchCondition.artist_id = item.id
        // this.searchCondition.nickname = item.nickname
      } else {
        this.searchCondition.artist_id = null
      }
      this.searchForm()
    }


  },
  created() {
    window.onscroll = () => {
      let scrollTop = document.documentElement.scrollTop
      // console.log('scrollTop', scrollTop)
      this.top = scrollTop
      this.fixHeader = scrollTop >= 120
    }
    this.id = this.$route.params && this.$route.params.id
    // this.getSetting()

  },
  mounted() {
    this.getInfo()
    this.$nextTick(() => {
      this.getPageList()
    })
  }
}
</script>

<style scoped>
.notice {
  border: #f2f2f2 1px solid;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}

.row-opt {
  border: #f2f2f2 1px solid;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}

.plc {
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
  padding: 10px;
}

.title-row {
  /*display: flex;*/
  text-align: center;
}

.title {
  flex: 6;
  font-weight: 700;
  font-size: 2rem;
  /*border: 1px solid rgba(255, 49, 118, 0.5);*/
  display: inline-block;
}

.tag {
  font-size: 1rem;
  font-weight: 600;
  color: red;
  display: inline-block;
}

.date {
  flex: 2;
  font-size: 0.9rem;
  font-weight: 600;
  color: red;
  display: inline-block;
}

.date::before {
  content: '有效期：';
  font-size: 1rem;
  font-weight: 700;
}

.notice-title {
  font-weight: 800;
  font-size: 1.2rem;
}

.plc-items {
  border: #f2f2f2 1px solid;
  border-radius: 10px;
  padding: 10px;
}


.price-div {
  display: flex;
  line-height: 100%;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
  /*border: #f2f2f2 1px solid;*/
  height: 100%;
  width: 100%;
}

.edit-btn {
  flex: 1;
  line-height: 100%;
  /*margin-right: 5px;*/
  vertical-align: middle;
  text-align: center;
  /*border: #0C0C0C 1px dashed;*/
  position: relative;
}

.edit-quote {
  position: relative;
  top: 45%;
  vertical-align: middle;
  text-align: center;
  line-height: 100%;
  /*border: #a41818 1px dashed;*/
}

.edit-btn-size {
  font-size: 18px;
}

.edit-box {
  border: #f2f2f2 1px dashed;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 5px;
}

.edit-row {
  padding: 10px;
  border: 1px solid rgba(242, 242, 242, 0.8);
  margin-top: 10px;
  border-radius: 10px;
  /*background-color: rgba(242,242,242,0.5)*/
}

.fanhui-button {
  float: right;
}

.bs_case {
  /**
  首行缩进
   */
  /*text-indent: 2em;*/
  display: -webkit-box;
  -webkit-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /*line-height: 300px;*/
}
</style>
<style>
.el-table .cell {
  white-space: pre-line;
}

.headerColor {
  color: #0000BB;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  /*left: 160px;*/
  z-index: 9;
  transition: width 0.28s;
  background: #fefefe;
  width: 98%;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  /*opacity: 0.9;*/
  /*margin-left: 10px;*/
}

.fold {
  left: 10px !important;
}

.unfold {
  left: 160px !important;
}

.info_box {
  margin: 10px 0;
  max-height: 500px;
  min-height: 200px;
  /*border: 1px solid rgb(0 0 0 / 10%);*/
  border-radius: 5px;
  padding: 8px;

}


.tox {
  z-index: 999999 !important;
  border: 1px solid red
}

.opt {
  border: #f2f2f2 1px dashed;
  padding: 10px;
  margin-top: 10px;
}
</style>
