<template>
  <div style='position: relative;min-width: 255px;text-align: left'>
    <div class='border-effect'>
      <div style='display: flex;flex-direction: row;gap:5px;margin-bottom: 6px'>
        <el-avatar :size='80' :src='data.cover_pc_url' @error='true' style='min-width: 80px'>
          <img src='https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png' />
        </el-avatar>
        <div style='margin-left: 10px'>
          <artist-nickname :info='{artist_id:data.artist_id,nickname:data.nickname}'
                           style='font-weight: 800;font-size: 2em' />
          <div>
            <span title='部门'>{{ data.dept_name || '/' }}</span>
          </div>
          <div>
            <!--              <span class="is-label">平台：</span>-->
            <el-link type='primary' :title='`平台：前往KOL（${data.nickname}）主页`' :href='data.home_page_link'
                     target='_blank'>
              <span class='is-value'>类型：{{ data.platform_name || '/' }}</span>
            </el-link>
          </div>
          <div>
            <span class='is-value' v-if='data.platform'>平台：{{ data.platform }}</span>
            <span class='is-value' v-if='data.platforms'>平台：{{ data.platforms.join('、') }}</span>
          </div>
          <div>
            <!--              <span class="is-label">平台ID：</span>-->
            <!--              <span class="is-value" title="平台ID">{{ data.account_id || '/' }}</span>-->
            <el-link type='primary' :title='`平台ID，点击前往KOL（${data.nickname}）主页`'
                     :href='data.home_page_link' target='_blank'>
              <span class='is-value'>{{ data.account_id || '/' }}</span>
            </el-link>
            <div>
              <span :title='`${data.nickname}肤质`' class='is-value'>{{ data.skin_type || '肤质暂无' }}</span>
            </div>


          </div>

        </div>
      </div>
      <div class='tag' :title='`${data.kol_label}标签`'>
        {{ data.kol_label || '标签暂无' }}
      </div>
      <span class='tag-alias' v-if='data.tag_alias'>{{ data.tag_alias || '' }}</span>
      <!--        <div>-->
      <!--          <span class="is-label">时间：</span>-->
      <!--          <span>{{ data.year || '/' }}年{{ data.month || '/' }}月</span>-->
      <!--        </div>-->

      <div class='intro' style='width: 220px'>
        <el-button type='text' icon='el-icon-edit-outline' @click='editIntro'
                   v-if="userPermissions.indexOf('plc_save_quote')>-1" class='edit-btn-size'></el-button>
        <span v-html='data.intro'></span>
      </div>
      <div style='text-align: center'>
        <el-link type='primary' @click='jumpToPlcItemDetail(data)'>查看提报详情</el-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PanelKolInfo',
  computed: {
    ...mapGetters(['userPermissions', 'userInfo'])
  },
  props: {
    data: {
      type: Object
    },
    index: {
      type: [String, Number]
    },
    platformType: {
      type: String,
      default() {
        return null
      }
    }
  },
  methods: {
    jumpToPlcItemDetail(row) {
      this.$router.push({
        name: 'plc_item', params: { id: row.id }, query: { type: this.platformType }
      })
    },
    editIntro() {
      this.$emit('editIntro', this.data, 'intro', this.index)
    }
  }
}
</script>

<style scoped>
.border-effect {
  padding: 5px;
  /*border: #f2f2f2 1px solid;*/
}

.is-label {
  display: inline-block;
  font-weight: 600;
  text-align: right;
}

/**
红人标签
 */
.tag-alias {
  position: absolute;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  /*padding: 2px;*/
  right: 5px;
  top: 5px;
  line-height: 36px;
  /*height: 40px;*/
  width: 36px;
  /*border: #F56C6C 1px solid;*/
  color: #ff3176;
  opacity: 0.8;
  border: #ff3176 1px solid;
  border-radius: 50%;
  z-index: 999;
}

.intro {
  /**
  首行缩进
   */
  /*text-indent: 2em;*/
  display: -webkit-box;
  /*display: -webkit-inline-flex;*/
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1em;
  font-weight: 400;
}

/*.intro::before {*/
/*  content: "简介：";*/
/*  font-weight: 600;*/
/*}*/

.edit-btn-size {
  font-size: 14px;
}

.tag {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2; /*控制行数*/
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>
