<template>
  <div>
    <div class='default-table'>
      <el-table class='tbl_plc' :data='tableData' row-key='field' default-expand-all
                :tree-props="{children: 'children', }"
                border>
        <el-table-column label='#' :align='`center`' width='60'>
          <template slot-scope='{$index}'>
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label='字段' prop='field' :align='`center`' min-width='120'>
          <template slot-scope='{row,column,$index}'>
            <div style='display: flex'>
              <div style='flex: 8'>
                <editable-cell
                  :show-input='row.editMode'
                  v-model='row[column.property]'
                  @change='saveVal(row,column.property,$index)' :is-input-number='false'>
                                                <span slot='content'>
                                                  <span>{{ row[column.property] }}</span>
                                                  <el-button type='text' icon='el-icon-edit'></el-button>
                                                </span>
                </editable-cell>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label='列名' prop='title' :align='`center`' min-width='160'>
          <template slot-scope='{row,column}'>
            <div style='display: flex'>
              <div style='flex: 8'>
                <el-input type='textarea' v-model='row[column.property]' />
                <!--                <editable-cell-->
                <!--                    :show-input="row.editMode"-->
                <!--                    v-model="row[column.property]"-->
                <!--                    @change="saveVal(row,column.property,$index)" :is-input-number="false">-->
                <!--                                                <span slot="content">-->
                <!--                                                  <span>{{ row[column.property] }}</span>-->
                <!--                                                  <el-button type="text" icon="el-icon-edit"></el-button>-->
                <!--                                                </span>-->
                <!--                </editable-cell>-->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label='行高' prop='height' :align='`center`' min-width='100'>
          <template slot-scope='{row,column,$index}'>
            <div style='display: flex'>
              <div style='flex: 8'>
                <editable-cell
                  :show-input='row.editMode'
                  v-model='row[column.property]'
                  @change='saveVal(row,column.property,$index)' :is-input-number='false'>
                                                <span slot='content'>
                                                  <span>{{ row[column.property] }}</span>
                                                  <el-button type='text' icon='el-icon-edit'></el-button>
                                                </span>
                </editable-cell>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label='列宽' prop='width' :align='`center`' min-width='100'>
          <template slot-scope='{row,column,$index}'>
            <div style='display: flex'>
              <div style='flex: 8'>
                <editable-cell
                  :show-input='row.editMode'
                  v-model='row[column.property]'
                  @change='saveVal(row,column.property,$index)' :is-input-number='false'>
                                                <span slot='content'>
                                                  <span>{{ row[column.property] }}</span>
                                                  <el-button type='text' icon='el-icon-edit'></el-button>
                                                </span>
                </editable-cell>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label='对齐方式' prop='horizontal' align='center'>
          <template slot-scope='{row,column}'>
            <el-select v-model='row[column.property]'>
              <el-option label='左对齐' value='left'>左对齐</el-option>
              <el-option label='居中' value='center'>居中</el-option>
              <el-option label='右对齐' value='right'>右对齐</el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label='操作' align='center' width='80'>
          <template slot-scope='{row,$index}'>
            <el-button icon='el-icon-delete' @click='delTblRow(row,$index)'></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style='padding: 10px;display: flex;flex-direction: row;gap: 5px;justify-content: center'>
<!--        <el-button type='danger' @click='handleClear'>清空</el-button>-->
        <el-button size='medium' type='primary' @click='handleSave'>保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
import EditableCell from '@/components/EditableCell'

export default {
  name: 'PlcColumnsTable',
  components: { EditableCell },
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    saveVal(row, colProp, index) {
      console.log(row)
      console.log(colProp)
      console.log(index)
      // this.$set(this.excelDataList[index], 'editMode', true)
      // this.excelDataList[index]['editMode'] = true
      // row.editMode = true
      // this.$notify.info('bianj')
      // this.$forceUpdate()

    },
    delTblRow(row, index) {
      this.tableData.splice(index, 1)
      this.$notify.success(`移除【${row.title}】`)
    },
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector('.tbl_plc > .el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)
        }
      })
    },
    handleClear() {
      this.$confirm('确认清空列设置？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.tableData = []

        this.$emit('handleSave')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    handleSave() {
      this.$emit('handleSave')
    }
  },
  mounted() {
    this.rowDrop()
  }

}
</script>

<style scoped>

</style>
