<template>
  <div class='border-effect'>
      <div>
        <el-row :gutter="15">
<!--          <el-col :span="24">-->
<!--            <span class="is-label">平台：</span>-->
<!--            <el-link type="primary" @click="openUrl(data.home_page_link)"-->
<!--                     :title="data.home_page_link">-->
<!--              <span class="is-value"><i class="el-icon-link"></i>{{ data.platform_name || '/' }}</span>-->
<!--            </el-link>-->
<!--          </el-col>-->
<!--          <el-col :span="12">-->
<!--            <span class="is-label">平台ID：</span>-->
<!--            <span class="is-value">{{ data.account_id || '/' }}</span>-->
<!--          </el-col>-->
          <el-col :span="24">
            <div :title="`最近的广告排期`">
              <span class="is-label">最近排期：</span>
              <span class="is-value">{{ data.last_release_date || '/' }}</span>
            </div>
          </el-col>
          <el-col :span="24">
            <span class="is-label">所在地区：</span>
            <span class="is-value">{{ data.city || '/' }}</span>
          </el-col>
          <el-col :span="24">
            <span class="is-label">粉丝(万)：</span>
            <span class="is-value">{{ $utils.numberFormat(data.fans_total / 10000, 2) }}</span>
          </el-col>
          <el-col :span="24">
            <div :title="`下月底预估粉丝量`">
              <span class="is-label">预估粉丝(万)：</span>
              <span class="is-value">{{ $utils.numberFormat(data.pre_fans_total / 10000, 2) }}</span>
            </div>
          </el-col>
          <el-col :span="24">
            <span class="is-label">赞藏数：</span>
            <span class="is-value">{{ data.zc_total || '/' }} 万</span>
          </el-col>
          <el-col :span="12">
            <div :title="`当月广告订单数`">
              <span class="is-label">当月订单数：</span>
              <span class="is-value"><span>{{ data.order_total || '/' }}</span></span>
            </div>
          </el-col>
          <el-col :span="12">
            <div :title="`下月广告订单数`">
              <span class="is-label">下月订单数：</span>
              <span class="is-value">{{ data.next_order_total || '/' }}</span>
            </div>
          </el-col>

          <el-col :span="12">
            <span class="is-label">直播时间：</span>
            <span class="is-value">{{ data.lb_time || '/' }}</span>
          </el-col>
          <el-col :span="12">
            <span class="is-label">直播案例：</span>
            <span class="is-value">{{ data.lb_case || '/' }}</span>
          </el-col>
          <el-col :span="12">
            <span class="is-label">女粉丝比例：</span>
            <span class="is-value">{{ data.female_fans_ratio || '/' }}</span>
          </el-col>
          <el-col :span="12">
            <span class="is-label">平均在线：</span>
            <span class="is-value">{{ data.avg_online || '/' }}</span>
          </el-col>
        </el-row>
      </div>
      <div>
        <el-tag type="primary" class="p-service" v-if="data.opened_star==='Y'">开通星图</el-tag>
        <el-tag type="primary" class="p-service" v-if="data.opened_window==='Y'">开通橱窗</el-tag>
        <el-tag type="primary" class="p-service" v-if="data.is_partner==='Y'">为品牌合作人</el-tag>
        <el-tag type="primary" class="p-service" v-if="data.opened_cart==='Y'">开通购物车</el-tag>
      </div>

  </div>
</template>

<script>
export default {
  name: "PanelPlatformInfo",
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    openUrl(href) {
      let url = ['http://', 'https://', '//'].indexOf(href) > -1 ? href : `https://${href}`
      window.open(url, '_blank')
    },
  }
}
</script>

<style scoped>
.p-service {
  margin: 5px;
}

.is-label {
  display: inline-block;
  font-weight: 600;
  text-align: right;
}
.border-effect{
  padding: 5px;
  /*border: #f2f2f2 1px solid;*/
}

</style>
