<template>
  <div style='padding: 5px 10px' class='border-effect'>
    <div style='margin-bottom: 10px'>
      <span class='opt-title'>{{ title }}-报价明细</span>
    </div>
    <div v-if='showMultiPrice'>
      <div style='margin-bottom: 5px' v-if='quoteInfo&&quoteInfo.multi'>
        <div style='text-align: center;margin-bottom: 8px'>
          <!--          <span style='font-weight: 800;padding: 10px;font-size: 18px'>品类/场次/直播费用/时长/权益</span>-->
          <el-button type='text' icon='el-icon-plus' style='float: right' @click='addMultiRow'
                     :disabled='!editPrice'>
            添加
          </el-button>
        </div>
        <el-table :data='quoteInfo.multi' v-if='quoteInfo&&quoteInfo.multi' border>
          <template v-for='(col,index) in quoteMultiCols'>
            <el-table-column :label='col.title' :prop='col.field' align='center' min-width='140'
                             :key='`${col.field}_${index}`'>
              <template slot-scope='{row,column}'>
                <!--                <el-input v-model='row[column.property]' :disabled='!editPrice' clearable />-->
                <div v-if='editPrice'>
                  <el-input :style='`width: ${input_width}`' v-if='col.type==="input"' v-model='row[column.property]'
                            :placeholder='col.placeholder' clearable></el-input>
                  <el-select :style='`width: ${input_width}`' v-else-if='col.type==="select"' filterable allow-create
                             v-model='row[column.property]'
                             :placeholder='col.placeholder' clearable>
                    <el-option v-for='(option,idx) in col.options'
                               :value='option.value' :label='option.label' :key='idx'></el-option>

                  </el-select>
                </div>
                <div v-else>
                  <span>{{ row[column.property] }}</span>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column label='操作' align='center' min-width='60'>
            <template slot-scope='{$index}'>
              <el-button type='text' icon='el-icon-delete' @click='delMultiRow($index)'
                         :disabled='!editPrice'></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <br />
    <div v-if='showSinglePrice'>
      <div v-if='quoteInfo.single'>
        <!--        <div style='margin-bottom: 8px'>-->
        <!--          <span style='font-weight: 800;font-size: 1em'>报价明细</span>-->
        <!--        </div>-->
        <div v-for='(item,index) in quoteInfo.single' :key='index'>
          <div style='display: flex'>
            <span class='is-label' style='flex: 2;'>{{ item.title }}</span>
            <div style='flex: 3'>
              <el-input :placeholder="item.placeholder||'请输入当月报价'" v-model='item.val'
                        type='number' :disabled='!editPrice' clearable>
              </el-input>
            </div>
            <div style='flex: 5;margin-left: 5px'>
              <el-input placeholder='请输入备注信息' v-model='item.remark' :disabled='!editPrice' clearable
                        style='width: calc(100% - 30px);margin-right: 10px;'
              />
              <el-button type='text' icon='el-icon-delete' :disabled='!editPrice' @click='quoteInfo.single.splice(index,1)' />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if='quoteInfo &&quoteInfo.length>0'>
          <div>
            <span style='font-weight: 800'>报价明细</span>
          </div>
          <div v-for='(item,index) in quoteInfo' :key='index'>
            <div style='display: flex;justify-content: flex-start;gap: 5px;align-items: center'>
              <span class='is-label' style='flex: 2;'>{{ item.title }}</span>
              <div style='flex: 3'>
                <el-input :placeholder="item.placeholder||'请输入下月预估价格'" v-model='item.val'
                          type='number' :disabled='!editPrice' clearable>
                </el-input>
              </div>
              <div style='flex: 5;margin-left: 5px'>
                <el-input placeholder='请输入备注信息' v-model='item.remark' :disabled='!editPrice' clearable
                          style='width: calc(100% - 30px);margin-right: 10px;'
                />
                <el-button type='text' icon='el-icon-delete' :disabled='!editPrice' @click='quoteInfo.splice(parseInt(index),1)' />
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>


<script>
import plcMap from '@/json/plc.json'
import MlPageTable from '@/components/common/MlPageTable'

export default {
  name: 'AdjustPricePanel',
  components: { MlPageTable },
  props: {
    title: {
      type: String,
      default() {
        return '报价'
      }
    },
    //报价信息-Array
    quoteInfo: {
      type: [Array, Object],
      default() {
        return { multi: [], single: [] }
        //multi: 报价信息：KOL = n:1
        //single: 报价信息：KOL = 1:1
        //表格导出时候需要区分，用于行合并
      }
    },
    editPrice: {
      type: Boolean,
      default() {
        return false
      }
    },
    platformType: {
      type: String,
      default() {
        return 'ONLINE'
      }
    },
    showMultiPrice: {
      type: Boolean,
      default() {
        return false
      }
    },
    showSinglePrice: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {
      input_width: '100%'
    }
  },
  computed: {
    quoteMultiCols() {
      if (plcMap && plcMap.quoteInfo && plcMap.quoteInfo.multi)
        return plcMap.quoteInfo.multi[this.platformType] || []
      return []
    },
    //报价方式：品类报价（1：n）；类目报价
    priceTypeMap() {
      let customPriceMap = plcMap.customPriceMap

      return customPriceMap[this.platformType] || {
        'multi': true,
        'single': true
      }
    }
  },
  methods: {
    addMultiRow() {
      if (this.quoteInfo && !this.quoteInfo.multi)
        this.$set(this.formData, 'multi', [])


      this.quoteInfo.multi.push({})
    },
    delMultiRow(index) {
      if (!this.quoteInfo.multi)
        this.$set(this.quoteInfo, 'multi', [])

      this.quoteInfo.multi.splice(index, 1)
    }
  }

}
</script>

<style scoped>
.opt-title {
  font-weight: bold;
  font-size: 1.1em;
  /*border: #ff3176 1px solid;*/
  padding: 5px;
  border-radius: 10px 10px;
  margin-bottom: 8px;
}

.border-effect {
  border: #f2f2f2 1px solid;
  padding: 10px;
}
</style>
