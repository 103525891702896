<template>
  <div>
    <el-input type='textarea' v-model='txtArea' rows='20'>

    </el-input>
  </div>
</template>

<script>
export default {
  name: 'PlcSetting',
  props: {
    SettingData: {
      type: Object
    }
  },
  computed:{
    txtArea(){
      return   JSON.stringify(this.SettingData, null, 4)
    }
  }
}
</script>

<style scoped>

</style>
